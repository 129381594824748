import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Home } from './containers/Home'
import { AboutMe } from './containers/AboutMe'
import { Projects } from './containers/Projects'
import { NotFound } from './containers/NotFound'

export const Routes = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/about'>
        <AboutMe />
      </Route>
      <Route exact path='/projects'>
        <Projects />
      </Route>
      {/* Catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
