import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  font-size: 1.5em;
  line-height: 6em;
  font-weight: 500;
`

export const BackButtom = () => <Container to='/'>&#8592; Back</Container>
