import React from 'react'
import styled, { keyframes } from 'styled-components'

const Neon = keyframes`
 0% {
  background-color: #b9fffc;
  }
  20% {
    background-color: #a3d8f4; 
  }
  40% {
    background-color: #78c4d4;
  }
  60% {
    background-color: #9ab3f5;
  }
  80% {
    background-color: #b4aee8;
  }
  100% {
    background-color: #7579e7;
  }
`

const Container = styled.a`
  text-decoration: none;
  color: #121314;
  position: relative;

  :after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 60%;
    left: -0.1em;
    right: -0.1em;
    bottom: 0;
    transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1);
    background-color: #ccf2f4;
    animation: ${Neon} 15s infinite alternate;
    -webkit-animation: ${Neon} 15s infinite alternate;
    -moz-animation: ${Neon} 15s infinite alternate;
  }

  :hover:after {
    top: 0%;
  }

  @media (max-width: 768px) {
    background-color: #ccf2f4;
    animation: ${Neon} 15s infinite alternate;
    -webkit-animation: ${Neon} 15s infinite alternate;
    -moz-animation: ${Neon} 15s infinite alternate;

    :after {
      position: unset;
    }
  }
`

export const PageLink = ({ children, href, target, rel }) => (
  <Container href={href} target={target} rel={rel}>
    {children}
  </Container>
)
