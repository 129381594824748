import React from 'react'
import styled from 'styled-components'

import { Links } from '../components/Links'
import { BackButtom } from '../components/BackButtom'
import { PageLink } from '../components/PageLink'

const Text = styled.p`
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.4;
`

export const AboutMe = () => {
  return (
    <div>
      <h1>About Me.</h1>
      <Text>
        As a programming enthusiast since childhood, I would like to share my
        passion with other like-minded individuals and put all that I have
        learned into practice. I am a natural problem solver, quickly learn new
        skills, programming languages, responsive web applications and methods
        of organizing code such as Model View Controller and Flux. So far, I
        have JavaScript, React, Python, Java, Ruby, Node.js, PyTorch, GraphQL,
        Redux, Express, Sinatra, Spring, MongoDB, PostgreSQL, HTML, CSS, Jira,
        GitHub and Git under my belt.
      </Text>
      <Text>
        Recently, working for People's Energy as Frontend Developer, I have been
        involved in the migration of the legacy code built-in EmberJS to React,
        NextJS, Typescript, NodeJS and Express. Also, I worked on internal
        automation projects such as sending the paper billing for a customer.
        Our site was deployed at AWS using EBS (Elastic Beanstalk). Also, we
        used AWS lambda, RDS, Dynamo DB and Event Bridge in our projects.
        Working in a fast-paced and results-driven environment, I learned how to
        manage conflicts, and made me an excellent team player capable of
        working to tight deadlines.
      </Text>
      <Text>
        Also, as Junior Software Developer, working for Fettle on developing and
        training deep learning models using the latest technologies such as
        Python, PyTorch and FastAI library. Also, I was responsible for the
        creation of a yoga pose detection module. And then a module to process a
        yoga video. Additionally, working building UI components for our front
        end service, I had the opportunity to improve my skills set utilising
        React, GraphQL, Storybook and Styled Components.
      </Text>
      <Text>
        I am a graduate in{' '}
        <PageLink
          href='https://drive.google.com/file/d/1w5pr8RU2NaEuyrN4vyAfmkxGF7XpHgzW/view?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
        >
          Software Development (SCQF level 8)
        </PageLink>{' '}
        at CodeClan. The course provides a lot of practical experience totalling
        at around 800 hours during which four full projects (of which three were
        a group project) are created independently from instruction, what gave
        me the experience of working in an agile development environment. In one
        of the projects, I had the opportunity to gain experience working
        directly with{' '}
        <PageLink
          href='https://drive.google.com/file/d/1R2fFPThHNrvxUc1W4uKFYQ-8MTjPLO7M/view?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
        >
          Feniks
        </PageLink>{' '}
        - Counselling, Personal Development and Support Service. The task was to
        redesign their existing front-end, update the back-end and to make a
        user authentication with AWS Cognito (Sigh up, Login, Logout and email
        verification).
      </Text>
      <Text>
        Before that, working for 4 years as an administrator, I have been
        involved in managing others, recruitment of new staff and completing
        administrative activities for a valet parking company. This role also
        required me to train new employees and help create the energy and
        excitement necessary to deliver great customer experience.
      </Text>
      <Text>
        Previously, I worked for 7 years with public policies requiring me to
        lobby Senators and Congressmen in the Brazilian National Congress.
        Lobbying has taught me negotiation skills, the need for contingency
        plans, and the ability to make quick yet innovative decisions. My
        responsibilities included developing and organising the political agenda
        of clients in a competitive environment.
      </Text>
      <Links />
      <BackButtom />
    </div>
  )
}
