import React from 'react'
import styled from 'styled-components'

import { ProjectItem } from '../components/ProjectItem'
import { BackButtom } from '../components/BackButtom'

import PROJECTS_DATA from '../data/projects.data'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1500px) {
    justify-content: space-around;
  }

  @media (max-width: 1100px) {
    justify-content: space-between;
  }
`

export const Projects = () => {
  const projects = PROJECTS_DATA

  return (
    <div>
      <h1>Projects.</h1>
      <Container>
        {projects.map(({ id, ...otherProjectProps }) => (
          <ProjectItem key={id} {...otherProjectProps} />
        ))}
      </Container>
      <BackButtom />
    </div>
  )
}
