const PROJECTS_DATA = [
  {
    title: 'Fettle',
    description:
      'Fettle is a studio content service for fitness instructors, yoga instructors, personal trainers, etc. Also, with Fettle you can build your own branded platform, live-stream workouts, offer video on demand and collect payment direct: all in one streamlined service.',
    imageUrl: 'assets/images/fettle.png',
    id: 1,
    url: 'https://www.fettle.io',
    linkGitHub: ''
  },
  {
    title: 'Clothing Store',
    description:
      'The clothing store is a full E-commerce app built with React, Redux, Stripe and Firebase. You can sign up a new user and sign in with Google to buy and save items in your cart. Furthermore, you can add, see and update items in your cart. Finally, at the checkout page integrated with Stripe API, you can manage the payments.',
    imageUrl: 'assets/images/clothing-store.png',
    id: 2,
    url: 'https://the-crown-clothing-store.herokuapp.com/',
    linkGitHub: 'https://github.com/diegodinizz/clothing-store'
  },
  {
    title: 'Cake Store - AWS',
    description:
      'Cake Store is a serverless application built with React, AWS API Gateway, AWS Lambda and AWS DynamoDB. The RESTful API was created using AWS API Gateway, AWS Lambda functions for automation the requests and AWS DynamoDB to store cakes data. The application allows the user to view, add and delete to a list of favorite cakes.',
    imageUrl: 'assets/images/cake-store.png',
    id: 3,
    url: 'https://the-cake-store.herokuapp.com',
    linkGitHub: 'https://github.com/diegodinizz/cake-store'
  },
  {
    title: 'Phonewords Converter',
    description:
      'The Phonewords Converter is a Full Stack Application built with Node and Express as Backend and React, Redux and Styled Components as Frontend. Also, the project is hosted on Heroku. This application is a converter from phone numbers to Phonewords. You can input numbers, and it will give you a list of all the possible phonewords for that specific phone number.',
    imageUrl: 'assets/images/phonewords.png',
    id: 4,
    url: 'https://phonewords-converter.herokuapp.com',
    linkGitHub: 'https://github.com/diegodinizz/phonewords-converter'
  },
  {
    title: 'Astro Notes',
    description:
      'Astro Notes is an application built with React, Redux, Styled Components, Moment.js and hosted on Heroku. The application allows users to create, edit and delete quick notes.',
    imageUrl: 'assets/images/astronotes.png',
    id: 5,
    url: 'https://astro-notes.herokuapp.com',
    linkGitHub: 'https://github.com/diegodinizz/astro-notes'
  },
  {
    title: 'SpaceX Lauches',
    description:
      'The SpaceX Launches is an application built with React, Redux, Styled Components, and Moment.js. The application consumes data from the SpaceX public API to retrieves historic and future SpaceX launches, and then display them in a list view. Also, you can filter by year and sort ascending and descending.',
    imageUrl: 'assets/images/spacex-launches.png',
    id: 6,
    url: 'https://diegodinizz.github.io/spacex-launches/',
    linkGitHub: 'https://github.com/diegodinizz/spacex-launches'
  },
  {
    title: 'Weather App',
    description:
      'An application used to check the currently Edinburgh - UK forecast, built with React, Styled Components and OpenWeatherMap 5 day weather forecast API.',
    imageUrl: 'assets/images/weather-app.png',
    id: 7,
    url: 'https://diegodinizz.github.io/5-day-forecast/',
    linkGitHub: 'https://github.com/diegodinizz/5-day-forecast'
  },
  {
    title: 'Authentication - GraphQL',
    description:
      'The Authentication is a web application which allows you to Sign Up and Sign In using React and GraphQL for queries. Also, the application uses MongoDB to store user data and PassportJS to handle the authentication, login and sign up users. So the app saves users cookies so that we can know them at the login page and to sign up a new user.',
    imageUrl: 'assets/images/auth.jpg',
    id: 8,
    url: '',
    linkGitHub: 'https://github.com/diegodinizz/GraphQL-Auth'
  },
  {
    title: 'Create Lyrics - GraphQL',
    description:
      'Create Lyrics is a collaborative songwriting application using React, Express, Webpack, MongoDB, Apollo Client and GraphQL for queries. The application allows you to create multiple songs and add lyrics to them; users can give likes to individual lines. All songs, lyrics and likes are then stored on the MongoDB database.',
    imageUrl: 'assets/images/create-lyrics.jpg',
    id: 9,
    url: '',
    linkGitHub: 'https://github.com/diegodinizz/GraphQL-Lyrical'
  },
  {
    title: 'The Gym',
    description:
      'The Gym application is a gym management system built using Ruby, Sinatra and PostgreSQL to store members and classes data. The app allows gym staff to sign up new members, edit memberships, add new classes and edit and book existing ones.',
    imageUrl: 'assets/images/the-gym.png',
    id: 10,
    url: 'https://the-gym.herokuapp.com',
    linkGitHub: 'https://github.com/diegodinizz/Project-Gym-System'
  },
  {
    title: 'Alcohol Tracker',
    description:
      'Alcohol Tracker is a web application built with vanilla JS, Express, CanvasJS and MongoDB to store user data. The app allows the user to set up a budget, add different drinks, quantity and log their monthly alcohol consumption. As a result, you can see by the use of beautiful charts, the current spend, remaining to spend and consumed alcohol units.',
    imageUrl: 'assets/images/alcohol-tracker.jpg',
    id: 11,
    url: '',
    linkGitHub: 'https://github.com/diegodinizz/Project-Alcohol-Tracker'
  },
  {
    title: 'Battle Game',
    description:
      'Battle Game is a fun game that allows the player to create a character, select from existing sprites, add health points and power points to the character and play against an enemy. The game is built with React, Spring and all players data are stored in the PostgreSQL database.',
    imageUrl: 'assets/images/battle-game.jpg',
    id: 12,
    url: '',
    linkGitHub: 'https://github.com/diegodinizz/Project-Battle-Game'
  }
]

export default PROJECTS_DATA
