import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: 30px 0 30px 0;
  align-items: center;
  justify-content: space-between;
  width: 550px;

  @media (max-width: 1300px) {
    width: 500px;
  }

  @media (max-width: 1024px) {
    width: 450px;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 20%;
  }

  a {
    font-weight: 400;
    font-size: 1em;
  }

  img {
    width: 24px;
    height: 24px;

    @media (max-width: 800px) {
      width: 20px;
      height: 20px;
    }
  }
`

export const Links = () => {
  return (
    <Container>
      <img src='https://i.ibb.co/0hs0f7L/email.png' alt='email-icon' />
      <a
        href='mailto:diegodinizz@gmail.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        diegodinizz@gmail.com
      </a>
      <img src='https://i.ibb.co/3hS3QPz/mobile.png' alt='mobile-icon' />
      <a href='tel:+44 07412 247752' target='_blank' rel='noopener noreferrer'>
        +44 07412 247752
      </a>
      <img src='https://i.ibb.co/nj8Tw7v/document.png' alt='cv-icon' />
      <a
        href='https://drive.google.com/file/d/166CIKkicGiYzE_0ZmDimJdbxcvVOyInf/view?usp=sharing'
        target='_blank'
        rel='noopener noreferrer'
      >
        CV Download
      </a>
    </Container>
  )
}
