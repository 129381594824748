import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: calc(10px + 0.33vw);
  -webkit-font-smoothing: antialiased;
  padding: 10vh 10vw 0 10vw;
  color: #121314;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 2em;
  }
}

  h1 {
  font-size: 4.5em;
  font-weight: 500;
  margin: 0 auto;
}

  a {
  text-decoration: none;
  color: #121314;
}
`
