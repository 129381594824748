import React from 'react'
import styled from 'styled-components'

import { Links } from '../components/Links'
import { PageLink } from '../components/PageLink'

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 2.2em;
`

const Text = styled.p`
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.4;
  width: 50%;

  @media (max-width: 1500px) {
    width: 55%;
  }

  @media (max-width: 1300px) {
    width: 60%;
  }

  @media (max-width: 1100px) {
    width: 65%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Footer = styled.a`
  font-size: 1.5em;
  line-height: 5em;
  font-weight: 500;
`

export const Home = () => {
  return (
    <div>
      <h1>Hello World.</h1>
      <Subtitle>My name is Diego Diniz.</Subtitle>
      <Text>
        I am a Full Stack Software Developer, and I love to build and implement
        functional applications, work with other developers and Product Managers
        through the Software Development life cycle.
      </Text>
      <Text>
        Most of my <PageLink href='/projects'>projects</PageLink> are available
        on{' '}
        <PageLink
          href='https://github.com/diegodinizz'
          target='_blank'
          rel='noopener noreferrer'
        >
          GitHub
        </PageLink>{' '}
        and you may find out more <PageLink href='/about'>about me</PageLink>{' '}
        and work experience via{' '}
        <PageLink
          href='https://www.linkedin.com/in/diego-diniz-da-silva/'
          target='_blank'
          rel='noopener noreferrer'
        >
          LinkedIn.
        </PageLink>
      </Text>
      <Text>
        Recently I have started learning Typescript, NextJS, Python, Deep
        Learning and Machine Learning. I am still enthusiastically grabbing onto
        any other programming languages, frameworks, or principles I can
        integrate into the code.{' '}
      </Text>
      <Footer
        href='https://blacklivesmatter.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        #BlackLivesMatter
      </Footer>
      <Links />
    </div>
  )
}
