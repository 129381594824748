import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 400px;
  height: auto;
  margin-top: 60px;
  margin-right: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  @media (max-width: 1500px) {
    margin-right: 0;
  }

  @media (max-width: 1000px) {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 414px) {
    width: 100%;
    min-width: 320px;
    margin-top: 30px;
  }
`

const Thumbnail = styled.img`
  width: 100%;
  height: 260px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-top: 1px solid rgba(212, 212, 217, 0.5);
`

const Title = styled.p`
  font-weight: 500;
  font-size: 1.3em;
  margin: 0;
  color: #41444b;
`
const turnArrow = css`
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transition: transform 300ms ease-out;
`

const Details = styled.i`
  margin-right: 0.5rem;
  border: solid #41444b;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: transform 300ms ease-out;

  &.turn {
    ${turnArrow}
  }
`

const showDetails = css`
  display: block;
`

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  display: none;

  &.active {
    ${showDetails}
  }
`

const Description = styled.p`
  font-size: 1em;
  font-weight: 300;
  margin: 0 0 25px 0;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 4rem;

  img {
    width: 24px;
    height: 24px;

    @media (max-width: 414px) {
      width: 20px;
      height: 20px;
    }
  }
`

export const ProjectItem = ({
  imageUrl,
  title,
  description,
  url,
  linkGitHub
}) => {
  const [active, setActive] = useState(false)

  function renderIcons () {
    if (url && linkGitHub) {
      return (
        <LinksContainer>
          <a href={url} target='_blank' rel='noopener noreferrer'>
            <img src='https://i.ibb.co/JKXpB2F/link-icon.png' alt='link-icon' />
          </a>
          <a href={linkGitHub} target='_blank' rel='noopener noreferrer'>
            <img
              src='https://i.ibb.co/HVCL56C/github-icon.png'
              alt='github-icon'
            />
          </a>
        </LinksContainer>
      )
    }
    if (url) {
      return (
        <LinksContainer>
          <a href={url} target='_blank' rel='noopener noreferrer'>
            <img src='https://i.ibb.co/JKXpB2F/link-icon.png' alt='link-icon' />
          </a>
        </LinksContainer>
      )
    } else {
      return (
        <LinksContainer>
          <a href={linkGitHub} target='_blank' rel='noopener noreferrer'>
            <img
              src='https://i.ibb.co/HVCL56C/github-icon.png'
              alt='github-icon'
            />
          </a>
        </LinksContainer>
      )
    }
  }

  return (
    <Container>
      <Thumbnail src={imageUrl} alt='project-thumbnail' />
      <TitleContainer onClick={() => setActive(!active)}>
        <Title>{title}</Title>
        <Details className={active ? 'turn' : ''}></Details>
      </TitleContainer>
      <DetailContainer className={active ? 'active' : ''}>
        <Description>{description}</Description>
        {renderIcons()}
      </DetailContainer>
    </Container>
  )
}
